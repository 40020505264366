<template>
    <section class="home-section">
        <block-ui v-if="processing"/>
        <section-title title="Operations"></section-title>
        <section class="bg-mix">
            <div class="container">
                <div class="col-lg-15 my-3">
                    <div class="card rounded-0">
                        <div class="card-body pt-0">
                            <button class="btn btn-bb  btn-sm btn-rule" @click="optimizer">{{tr('Optimizer')}}</button>
                            <button class="btn btn-bb  btn-sm btn-save" @click="optimizerOutput">{{tr('Optimizer Log')}}</button>
                        </div>
                        <!--div class="card-body pt-0">
                            <button class="btn btn-bb  btn-sm btn-rule" @click="getAros">{{tr('Download Aros')}}</button>
                            <button class="btn btn-bb  btn-sm btn-save" @click="getArosOutput">{{tr('Download Aros Log')}}</button>
                        </div-->
                        <div class="card-body pt-0">
                            <button class="btn btn-bb  btn-sm btn-rule" @click="processAros">{{tr('Process Aros')}}</button>
                            <button class="btn btn-bb  btn-sm btn-save" @click="processArosOutput">{{tr('Process Aros Log')}}</button>
                        </div>
                        <div class="card-body pt-0">
                            <button class="btn btn-bb  btn-sm btn-save" @click="osProcess">{{tr('System Python Process')}}</button>
                        </div>
                    </div>
                    <div class="card rounded-0 output" id="showOutput">
                        <output-comp v-if="showOutput" :text="showOutput"></output-comp>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
import Vue from 'vue';

let outputComp = Vue.component('showOutput', {
    props: ['text'],
    template: `
      <div>
      </div>
    `,
    mounted () {
        if (this.text) this.$el.innerHTML = this.text.split('\n').join('<br>');
    },
    watch: {
        text (val) {
            if (this.text) this.$el.innerHTML = this.text.split('\n').join('<br>');;
        }
    },
})

import { mapState } from 'vuex';
export default {
    name: 'operations',
    components: {
        'output-comp': outputComp
    },
    data () {
        return {
            processing: false,
            showOutput: null,
        }
    },
    async mounted () {
    },
    methods: {
        async optimizer () {
            await api.get('/api/optimizer/');
        },
        async getAros () {
            await api.get('/api/get_aros/');
        },
        async processAros () {
            await api.get('/api/process_aros/');
        },
        async optimizerOutput () {
            this.showOutput = await api.get('/api/optimizer_output/');
        },
        async getArosOutput () {
            this.showOutput = await api.get('/api/get_aros_output/');
        },
        async processArosOutput () {
            this.showOutput = await api.get('/api/process_aros_output/');
        },
        async osProcess () {
            this.showOutput = await api.get('/api/get_os_process/');
        },
    },
}
</script>

<style scoped>
    .output {
        max-height: 70vh;
        overflow: scroll;
    }
</style>